/* == We use  jquery because default by required by Woocommerce / whosale ... == */

(function ($) {

  // ---------- APP START
  var App = {

    config: {
      scrollOffset: 150
    },

    init: function () {

      App.initMenu();
      App.initSlider();
      App.initCounterKeys();
      App.initAccordion();
      App.initFormation();
      App.initMapFilters();
      App.initForms();
      App.initGLB();
      App.WC.init();

      window.addEventListener('resize', function () {App.appHeight();});
      App.appHeight();
      
      $(window).scroll(function () {
        if ($(window).scrollTop() > 76) {
          $('body').addClass('is-shrink');
        } else {
          $('body').removeClass('is-shrink');
        }
      });
    },

    load: function(){
      if (window.location.hash && $(window.location.hash).length) {
        App.scroll(window.location.hash);
      }
    },

    scroll: function (element, offset) {
      offset = offset === undefined ? App.config.scrollOffset : offset;
      $('html, body').clearQueue().animate({ scrollTop: $(element).offset().top - offset }, 'fast');
    },

    initMenu: function () {
      var $current = '';
      var $action = '';
      var $navcontainer = null;
      $('.js-menu').on('click touch', function (e) {
        e.preventDefault();
        $action = $(this).data('id') == 'menu-menu-main-2' ? 'nav2-opened' : 'nav-opened';
        var $navtarget = $('#' + $(this).data('id'));
        $navcontainer = $navtarget.parent().parent();
        $navcontainer.attr('aria-hidden', 'false');
        $navtarget.addClass('active');
        if($action != $current) {
          $('body').removeClass($current);
        }
        $('body').addClass($action);
        $current = $action;
      });

      $('.js-menu-close, #navmenu, #navmenushop').on('click touch', function () {
        closeMainMenu();
      });

      function closeMainMenu() {
        $('.navigation-menu').removeClass('active');
        $navcontainer.attr('aria-hidden', 'true');
        $navcontainer.one('animationend', function () {
          $('body').removeClass($action);
        });
      };

      // Nav mobile
      $('.js-menu-burger').on('click touch', function (e) {
        e.preventDefault();
        var $nav_mb = $('.nav-mb');
        if($nav_mb.attr('aria-hidden') === 'false') {
          $nav_mb.attr('aria-hidden', 'true');
          $($nav_mb).one('animationend', function () {
            $('body').removeClass('nav-mb-opened');
          });
        }
        else {
          $nav_mb.attr('aria-hidden', 'false');
          $('body').addClass('nav-mb-opened');
        }

        var $mb_submenus_open = $('.nav-mb-menu--main').find('button');
        var $mb_submenus_back = $('.nav-mb-menu--main').find('span');

        $mb_submenus_open.on('click touch', function (e) {
         $(this).siblings('.nav-mb-submenu__inner').addClass('selected');
        });

        $mb_submenus_back.on('click touch', function (e) {
          $('.nav-mb-submenu__inner').removeClass('selected');
        });
      });

      $('.js-menu-mb-close').on('click touch', function () {
        var $parent = $(this).closest('.nav-mb');
        $parent.attr('aria-hidden', 'true');
        $parent.one('animationend', function () {
          $('body').removeClass('nav-mb-opened');
          $('body').removeClass('nav-mb-submenu-opened');
          $('body').removeClass('search-mb-opened');
        });
      });

      /* toggle search mobile
      ----------------------------------*/
      $('.js-toggle-search').on('click touch', function () {
        $('body').toggleClass('search-mb-opened');
      });

      /* Toggle optin newsletter footer
      ----------------------------------*/
      var $toggler = $('.wpforms-field-radio--toggle').find('.choice-1');
      $toggler.on('click touch', function () {
        if($(this).hasClass('wpforms-selected')) {
          $(this).removeClass('wpforms-selected');
          $(this).find('input').prop('checked', false);
          $(this).parent().find('.choice-2').addClass('wpforms-selected');
          $(this).parent().find('.choice-2').find('input').prop('checked', true);
        }
        else {
          $(this).addClass('wpforms-selected');
          $(this).find('input').prop('checked', true);
          $(this).parent().find('.choice-2').removeClass('wpforms-selected');
          $(this).parent().find('.choice-2').find('input').prop('checked', false);
        }
      });
    },

    /* Map filters
    ----------------------------------*/
    initMapFilters: function () {
      var $filter_map_wrapper = $('.map-filters__wrapper');

      if ($(window).width() >= 420) {
        $('.map-filters__wrapper').attr('aria-hidden', 'false');
      }
      else {
        $('.js-map-filters').on('click touch', function () {
          $('body').addClass('filters-opened');
          $filter_map_wrapper.attr('aria-hidden', 'false');
        });
  
        $('.js-map-filters-close').on('click touch', function () {
          $filter_map_wrapper.attr('aria-hidden', 'true');
          $filter_map_wrapper.one('animationend', function () {
            $('body').removeClass('filters-opened');
          });
        });
      }
    },

    /* Bloc Accordion
    ----------------------------------*/
    initAccordion: function () {

      if($('.tab-content').length) {

        $('.tab-label').on('click touch', function (e) {
          e.preventDefault();
          var $parent = $(this).parent();
          $('.tab').not($parent).removeClass('active');
          $parent.toggleClass('active');
        });
        
        $('.js-product-read-more').on('click touch', function (e) {
          var $desc =  $('.block-accordion').find('#description');
          $('.tab').removeClass('active');
          $($desc).addClass('active');
          App.scroll($desc);
        });

        function setheights() {
          $('.tab-content').each(function () {
            $(this).css("--height", parseInt(this.scrollHeight) + "px");
          });
        }

        window.addEventListener('resize', function () {setheights();});
        setheights();
      }
    },

    /* Sliders
    ----------------------------------*/
    initSlider: function () {
      $('.swiper').each(function (index, element) {
        var $offset = element.dataset.offset ? 0.2 : 0;
        var $items = parseFloat(element.dataset.items) + $offset;
        var $fullone = element.dataset.fullone ? 1 : 0;
        var $space = $items >= 6 ? 6 : 20;
        var $gap = element.dataset.gap ? element.dataset.gap : $space;
        var $nav = element.getElementsByClassName('swiper-pagination');
        new Swiper(element, {
          slidesPerView: $items,
          speed: 400,
          spaceBetween: $gap,
          autoHeight: $fullone ? false:true,
          pagination: {
            el: $nav[0],
            clickable: true
          },
          navigation: {
            nextEl: element.querySelector('.swiper-button-next'),
            prevEl: element.querySelector('.swiper-button-prev')
          },
          breakpoints: {
            320: {
              slidesPerView: $fullone ? 1:1.2
            },
            768: {
              slidesPerView: $fullone ? 1:2.4
            },
            1024: {
              slidesPerView: $items
            }
          }
        });
      });
    },

    /* Counters keys
    ----------------------------------*/
    initCounterKeys: function () {

      $('.card-key-unit').each(function () {
        var count = $(this),
          decimal = (count.text().indexOf('.') !== -1) ? true : false,
          unit = (count.text().indexOf('%') !== -1) ? count.text().substring(count.text().indexOf('%'), count.text().length) : false,
          to = (unit !== false) ? count.text().substring(0, count.text().indexOf('%')) : count.text(),
          performed = false,
          viewport = {},
          bounds = {};

        bounds.top = count.offset().top;
        bounds.bottom = bounds.top + count.outerHeight();

        performAnimation();

        $(window).on('scroll resize', performAnimation);

        function performAnimation() {
          viewport.top = $(window).scrollTop();
          viewport.bottom = viewport.top + $(window).height();

          if (performed === false && bounds.top <= viewport.bottom && bounds.bottom >= viewport.top) {
            count.prop('counter', 0).animate({
              counter: to
            }, {
              duration: 1500,
              step: function (now) {
                var value = (decimal !== false) ? now.toString().substring(0, now.toString().indexOf('.') + 2) : Math.ceil(now);
                if (unit !== false) { value += '' + unit; }
                count.text(value);
              }
            });
            performed = true;
          }
        }
      });
    },

    /* Formations - filters
    ----------------------------------*/
    initFormation: function () {

      $('.formation-city-js').on('change', function (e) {
        e.preventDefault();
        $selection = $(this).val();
        $('.card-formation').removeClass('hide');
        if ($selection != '') {
          $('.card-formation').each(function (el, index) {
            if ($(this).data('id') != $selection)
              $(this).addClass('hide');
          });
        }
      });
    },

    /* WPForms - Prefill fields
    ----------------------------------*/
    initForms: function () {

      // Case Form contact coiffeur
      if($('#wpforms-1119').length) {
        $('.contact-salon').on('click touch', function () {
          var $salon_name = $(this).data('name');
          // Find hidden field in form prebuild in page via popup maker
          var $input_target = $('#wpforms-1119-field_10');
          // Fill target with salon Name, to be used later in notifications
              $input_target.val($salon_name);
        });
      }
    },

    /* Popup - Glightbox
    ----------------------------------*/
    initGLB: function () {

      // We build one instance by video to set specific ratio.
      $('.js-video-play').each(function(el) {
        var $selector = '.' + $(this).data('sel');
        var $ratio = $(this).data('ratio') != "" ? $(this).data('ratio'):'16:9';
        const lightboxVideo = GLightbox({
          selector: $selector,
          skin: 'video',
          videosWidth: '1280px',
          plyr: {
            config: {
              ratio: $ratio,
              youtube: {
                noCookie: true,
                rel: 0,
                showinfo: 0,
                iv_load_policy: 3
              }
            }
          }
        });
      });

      if($('.js-get-weight').length) {
        var lightboxInline = GLightbox({
          selector: '.js-get-weight',
          skin: 'nuancier',
          width: '440px',
          height: 'auto',
          closeEffect: 'none'
        });

        lightboxInline.on('slide_after_load', (data) => {
          var $cont = $('.ginlined-content');
          var $target = $(data.trigger).data('call');
          $.ajax({
            type: 'GET',
            url: $target,
            dataType: 'html',
            success: function(data){
              $cont.append(data);
              App.initGetWeight($cont);
            }
          });
        });
      }

      if($('.js-map').length) {
        var lightboxMap = GLightbox({
          selector: '.js-map',
          skin: 'locator',
          openEffect: 'none',
          closeEffect: 'none',
          touchNavigation: false,
        });

        lightboxMap.on('slide_after_load', (data) => {
          var $cont = $('.ginner-container');
          var $target = $(data.trigger).data('call');
          $.ajax({
            type: 'GET',
            url: $target,
            dataType: 'html',
            success: function(data){
              var $page = $(data).find('.store-locator-with-filters');
              $cont.prepend($page);
              Locator.init();
              App.initMapFilters();
            }
          });

          document.addEventListener("onChoiceSelected", function(){
            lightboxMap.close();
            $('body').removeClass('filters-opened');
          });

          lightboxMap.on('close', () => {
            $('body').removeClass('filters-opened');
          });
        });
      }
    },

    /* Nuancier
    ----------------------------------*/
		initGetWeight: function(container){
      var $btn_calc = $(container).find('.submit-weight');
      var $btn_back = $(container).find('.submit-back');
						
      $btn_calc.on('click touch', function(e){
        e.preventDefault();
        var $weight   = $(container).find('.weight-input').val();
        var $formula  = $(container).find('.formula');
        var $result   = $(container).find('.formula-result');
        if($weight == '') return;
        $(container).addClass('has-result');
        $result.empty();
        
        $formula.each(function(el) {
          $value = $(this).val().split('|');
          $percent = Math.round(($value[1]/100) * parseInt($weight));
          $result.append('<p class="weight-qty">' + $percent + 'g ' + $value[0] + '</p>');
        });
      });
      
      $btn_back.on('click touch', function(e) {
        $(container).removeClass('has-result');
      });
		},

    /* Woocommerce
    ----------------------------------*/
    WC: {

      init: function () {

        // === Create account
        $('.woocommerce-form-register').on('submit', function (e) {
          var $usr_referral_salon = $('#usr_referral_salon');
          if($usr_referral_salon.val().length < 1) {
            $('.alert-salon-empty').addClass('alert');
            setTimeout(function(){ 
              $('.alert-salon-empty').removeClass('alert');
            }, 2500);
            return false;
          }
        });

        // === Toggle register form
        $('.toggle-register').on('click touch', function (e) {
          $('.form-register-wrapper').attr('aria-hidden', 'false');
          $(this).parent().attr('aria-hidden', 'true');
        });


        var $gift_optin = $('.shop_gift_optin').find('.acf-true-false label');
        $gift_optin.on('mouseup', function (e) {
          $message = $('.shop_gift_message');
          $message.toggle('show');
        });

        // === Endpoint My Salon
        $('.copy-input').on('click touch', function (e) {
          e.preventDefault();
          var copyMess = document.getElementById("input-copied");
          var copyText = document.getElementById("input-copy");
          copyText.select();
          copyText.setSelectionRange(0, 99999); // For mobile
          if(navigator.clipboard) {
            navigator.clipboard.writeText(copyText.value);
            copyMess.innerHTML = 'Url copiée : ' + copyText.value;
            copyMess.classList.add("copied");
          }
          else{
            copyMess.classList.add("copied");
            copyMess.innerHTML = 'Url copiée : ' + copyText.value;
          }
        });
        
        // === Endpoint My salaries
        $('.js-add-salary').on('click touch', function (e) {
          $('.my-employees__add-salary').toggleClass('active');
        });

        // === Grid products pro
        if($('.grid-products-pro').length) {
          App.WC.initMoreProducts();
        }

        // === Plus / Minus cart and minicart
        if($('.btn-product-quantity').length) {
          App.WC.plusMinus();
        }

        $(document).ajaxComplete(function (e, xhr, settings) {
          if(settings.url.indexOf('get_refreshed_fragments') === -1) {
            App.WC.plusMinus();
          }
        });

        // ========== Hack positionning autosuggest / form checkout ==========
        if($("#billing_autocomplete_field")) {
          var $autofill_fields = ['#billing_autocomplete_field','#shipping_autocomplete_field'];
          repositionAutocomplete();
        }

        function repositionAutocomplete() {
          setTimeout(function(){ 
            $(".pac-container").each(function (index) {
              var el = $($autofill_fields[index]);
                $(this).prependTo(el);
                el.find('input').attr("placeholder", "Rechercher mon adresse (facultatif)");
              }
            );
          }, 100);
        }
      },
	
      initMoreProducts: function(){
        $btns = $('.js-btn-moreproducts');
        $btns.on('click', function(e){
          e.preventDefault();
          $btn_wrap = $(this).parent();
          $section  = $btn_wrap.parent().find('.grid-products-pro');
          $spin     = $(this).find('.icon').addClass('loading');
          $.ajax({
            type: 'GET',
            url: $(this).attr('href'),
            dataType: 'html',
            success: function(data){
              $section.append(data);
              $spin.removeClass('loading');
              $btn_wrap.remove();
            }
          });
        });
      },

      plusMinus: function(){
        $('button.plus, button.minus').one( 'click', function() {
          var qty = $(this).parent('.quantity').find('.qty');
          var val = parseFloat(qty.val());
          var max = parseFloat(qty.attr('max'));
          var min = parseFloat(qty.attr('min'));
          var step = parseFloat(qty.attr('step'));
          if ($(this).is('.plus')) {
            if (val === max) return false;
            if(isNaN(val)) {qty.val(step);}
            else if (val + step > max) { qty.val(max);} 
            else {qty.val(val + step);}
          } else {
            if (val === min) return false;
            if(isNaN(val)) {qty.val(min);}
            else if (val - step < min) {qty.val( min );}
            else {qty.val(val - step);}
          }
          qty.val( Math.round(qty.val() * 100) / 100);
          qty.trigger("change");
        });
      }
    },

    appHeight: function() {
      var vh = window.innerHeight * 1;
      $(':root').css({'--vh': vh + 'px'});
    },

    /* Matomo Events
    ----------------------------------*/
    sendEvent: function(category, action, label) {
      _paq.push(['trackEvent', category, action, label]);
    },
  };

  // ---------- APP END

  // Init app
  $(document).ready(App.init);
  $(window).on('load', App.load);
  window.App = App;
  //window.gevt = App.gevt;

})(jQuery);


/*
// wc_fragment_refresh
		// wc_fragments_refreshed
		// wc_fragments_loaded
		// update_cart_totals
		/*window.addEventListener("wc_fragment_refresh", function(e) {
      console.log('wc_fragment_refresh');
    });
		
		$( document.body ).on('wc_fragments_refreshed', function(){
			console.log('wc_fragments_refreshed');
		});
		
		$( document.body ).on('updated_cart_totals', function(){
			console.log('updated_cart_totals');
		});
		
		
		$( document.body ).on('added_to_cart', function(){
			console.log('added_to_cart');
		});		
		
		$( document.body ).on('add_to_cart', function(){
			console.log('add_to_cart');
		});
		
		$( document.body ).on('wc_cart_created', function(){
			console.log('wc_cart_created');
		});
		
		$( document.body ).on('updated_wc_div', function(){
			console.log('updated_wc_div');
		});
		
		$('.entry-content').on('click', '.nf-fu-fileinput-button', function(e) {
			e.preventDefault();
		});
  */